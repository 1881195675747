import _ from 'lodash'
import flow from 'lodash/fp/flow'
import keys from 'lodash/fp/keys'
import reduce from 'lodash/fp/reduce'
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useMemo,
  useState,
  // useCallback,
} from 'react'
import { Formik } from 'formik'
import {
  cancelRequest,
  // useAuth,
  // useSystemSettings,
  useUser,
} from 'react-omnitech-api'
import { useAlert, useLink } from '../../hook'
import {
  transformResetPasswordFormValidationSchema,
  getResetPasswordFormInitialValues,
} from '../../helpers'
import ResetPasswordForm from './reset-password-form'
import RequestResetPasswordForm from './request-reset-password-form'
import ResetPasswordView from './reset-password-view'

function ResetPasswordController({
  pageContext: {
    locale,
  },
  resetPasswordToken,
}) {
  // prepare hook
  const alert = useAlert()
  // const { navigate } = useLink()
  // const { getSystemSetting } = useSystemSettings()
  // const { auth, createSession, setAuth } = useAuth()
  const { createUserResetPasswordRequest, createUserResetPassword } = useUser()
  const [email, setEmail] = useState('')

  // hook with dependencies
  // const formConfig = getSystemSetting('account.user_registration_fields', {})

  // internal states
  const [formDisabled, setFormDisabled] = useState(false)
  // const [pageReady, setPageReady] = useState(false)
  const [showRequestSuccessScreen, setShowRequestSuccessScreen] = useState(false)
  const [showSuccessScreen, setShowSuccessScreen] = useState(false)
  // const [loginApproach, setLoginApproach] = useState('email')

  // useMemo for caching variables
  const formInitialValues = getResetPasswordFormInitialValues(resetPasswordToken)

  const formValidationSchema = transformResetPasswordFormValidationSchema(resetPasswordToken)

  const requiredFields = useMemo(() => (
    flow(
      keys,
      reduce((result, current) => ({
        ...result,
        [current]: true,
      }), {}),
    )(formInitialValues)
  ), [formInitialValues])

  /**
   * handleResetPassword
   *
   * @param {*} values, object contain all value from input
   */
  async function handleResetPassword(values) {
    alert.remove()
    setFormDisabled(true)

    // prepare api call payload
    const data = {
      ...values,
      ...({ resetPasswordToken } || {}),
    }

    const action = resetPasswordToken ? createUserResetPassword : createUserResetPasswordRequest

    // calling api for create session and control the flow of page redirect
    try {
      await action(data)

      if (resetPasswordToken) {
        setShowSuccessScreen(true)
      } else {
        setEmail(_.get(values, 'email'))
        setShowRequestSuccessScreen(true)
      }
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)

      setFormDisabled(false)

      throw error
    }
  }

  /**
   * handleSubmit
   *
   * Formik onSubmit callback
   *
   * @param {*} values form values from Formik
   * @param {*} actions includes an object containing a subset of the injected props and methods
   */
  const handleSubmit = async (values, actions) => {
    try {
      await handleResetPassword(values)
    } catch (error) {
      // registration only return general error, does not has validation error
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    } finally {
      actions.setSubmitting(false)
    }
  }

  /**
   * cancel api call when leaving the page
   */
  useEffect(() => () => (
    cancelRequest.cancelAll(['createSession'])
  ), [])

  const withToken = !_.isEmpty(resetPasswordToken)

  const viewProps = {
    email,
    // pageReady,
    showSuccessScreen,
    showRequestSuccessScreen,
    withToken,
  }

  const formPorps = {
    formDisabled,
  }

  const Form = withToken ? ResetPasswordForm : RequestResetPasswordForm

  return (
    <ResetPasswordView {...viewProps}>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validateOnChange
        validationSchema={formValidationSchema}
        onSubmit={handleSubmit}
      >
        <Form {...formPorps} />
      </Formik>
    </ResetPasswordView>
  )
}

export default ResetPasswordController
