import React from 'react'
import { Router } from '@reach/router'
import _ from 'lodash'
import ResetPasswordScreen from '../screens/reset-password'

const ResetPasswordPage = (props) => (
  <Router>
    <ResetPasswordScreen {...props} path={`${_.get(props, 'pageContext.locale')}/reset-password/`} />
    <ResetPasswordScreen {...props} path={`${_.get(props, 'pageContext.locale')}/reset-password/:resetPasswordToken`} />
  </Router>
)

export default ResetPasswordPage
