import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
  },
  title: {
    textTransform: 'uppercase',
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25,
    marginBottom: '0.4rem',
  },
  intro: {
    fontSize: '1.6rem',
    lineHeight: 1.25,
    marginBottom: '1rem',
    color: theme.colors.disabled,
  },
  inputs: {
    marginBottom: '1.2rem',
  },
  requireStyle: {
    color: theme.colors.bgDark,
    fontSize: '1.2rem',
    marginBottom: '2rem',
    lineHeight: 1.14,
    '&:before': {
      content: '"*"',
      color: theme.colors.alert,
      marginRight: '0.3rem',
    },
  },
  registerButtonStyle: {
    display: 'block',
    width: '100%',
    border: `2px solid ${theme.colors.primary}`,
    textAlign: 'center',
    padding: '1.1rem 1.4rem',
    marginTop: '2rem',
    fontSize: '1.6rem',
    fontWeight: 600,
    lineHeight: 1.25,
    textDecoration: 'none',
    color: 'currentcolor',
    '& span': {
      font: 'inherit',
      lineHeight: 'inherit',
    },
  },
}))

export default useStyles
