import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './reset-password-style'
import {
  Layout,
  MessageScreen,
  SEO,
} from '../../ui'

function ResetPasswordView({
  children,
  email,
  showSuccessScreen,
  showRequestSuccessScreen,
}) {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <Layout>
      {/* passing page specific data for SEO */}
      {/* add seo title */}
      <SEO
        // description={seoDescription}
        title="Reset Password"
        // meta={seoMeta}
      />
      <div className={styles.containerFluid}>
        <div className={styles.messages}>
          {
            showSuccessScreen && (
              <MessageScreen
                title={t('screens.resetPassword.successScreen.title')}
                message={t('screens.resetPassword.successScreen.message')}
                button={{
                  text: t('screens.resetPassword.successScreen.buttons.done'),
                  navigate: '/login/',
                }}
              />
            )
          }
          {
            showRequestSuccessScreen && (
              <MessageScreen
                title={t('screens.resetPassword.requestSuccessScreen.title')}
                message={t('screens.resetPassword.requestSuccessScreen.message', { email, returnObjects: true })}
                button={{
                  text: t('screens.resetPassword.requestSuccessScreen.buttons.done'),
                  navigate: '/',
                }}
              />
            )
          }
        </div>
        <div className={styles.container}>
          {
            !(showSuccessScreen || showRequestSuccessScreen) && children
          }
        </div>
      </div>
    </Layout>
  )
}

export default ResetPasswordView
