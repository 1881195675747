import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  containerFluid: {
    width: '100%',
    padding: [[15, 20, 30]],
    [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
      padding: [[60, 0]],
    },
  },
  container: {
    maxWidth: '37rem',
    margin: '0 auto',
  },
  messages: {
    maxWidth: '50rem',
    margin: '0 auto',
  },
}))

export default useStyles
