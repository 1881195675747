/* eslint-disable react/jsx-props-no-spreading */
import _ from 'lodash'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Form,
  useFormikContext,
} from 'formik'
import useStyles from './reset-password-form-style'
import {
  Input,
  Button,
  Link,
} from '../../ui'

const LoginForm = ({
  formDisabled,
}) => {
  const { t } = useTranslation()
  const {
    isSubmitting,
    isValid,
    // setFieldValue,
    submitForm,
    values,
  } = useFormikContext()

  const handleSubmitForm = () => {
    submitForm()
  }

  const isFormEmpty = useMemo(() => (
    _.every(values, (value) => (_.isEmpty(value)))
  ), [values])

  // styles
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>{t('screens.resetPassword.requestResetPasswordForm.title')}</h3>
        <p className={styles.intro}>{t('screens.resetPassword.requestResetPasswordForm.intro')}</p>
      </div>
      <Form noValidate>
        <div className={styles.inputs}>
          <Input
            formik
            label={t('screens.resetPassword.requestResetPasswordForm.email')}
            name="email"
            type="email"
            autoComplete="email"
            required
          />
        </div>
        <p className={styles.requireStyle}>{t('screens.resetPassword.requestResetPasswordForm.required')}</p>
        <Button
          dark
          disabled={isFormEmpty || formDisabled || isSubmitting || !isValid}
          fontWeight={600}
          padding="1.25rem"
          size="1.6"
          text={t('screens.resetPassword.requestResetPasswordForm.buttons.submit')}
          uppercase
          onClick={handleSubmitForm}
        />
        <Link className={styles.registerButtonStyle} to="/login/">
          <span>{t('screens.resetPassword.requestResetPasswordForm.buttons.login')}</span>
        </Link>
      </Form>
    </div>
  )
}

export default LoginForm
